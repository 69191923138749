//
//
//
//
//
//


import { LocalStorage } from "quasar";
export default {
  name: "Index",
  data() {
    return {
      userId: 0
    };
  },

  mounted() {
    window.document.title = "会员详情";

    // var script = document.createElement('script');
    // script.src = 'https://cdn.bootcdn.net/ajax/libs/vConsole/3.9.0/vconsole.min.js'
    // document.getElementsByTagName('body')[0].appendChild(script)
    // this.$toast.loading({
    //   message: '',
    //   forbidClick: true,
    //   duration: 0
    // });
    // script.onload = () => {
    //   var vConsole = new VConsole();
    //   // this.wxConfig(() => {
    //   //   this.wxAgentConfig();
    //   // })
    //    this.wxAgentConfig()
    // }
        this.$toast.loading({
      message: '',
      forbidClick: true,
      duration: 0
    });

    this.wxAgentConfig()

  },
  activated() {
    window.document.title = "会员详情";
  },
  created() {
    window.document.title = "会员详情";

  },
  methods: {

    wxConfig(callback) {
      console.log(1)
      const that = this;
      let agentId = LocalStorage.getItem("agentId")
      let corpId = LocalStorage.getItem("corpId")
      this.axios
        .get("/" + this.$ajaxPrefix.consumer + "/v1/wx/cp/getJsSdkConfig", {
          params: {
            agentId,
            corpId,
            url: encodeURIComponent(window.location.href.split("#")),
          },
        })
        .then((res) => {
          if (res && res.data && res.data.data) {
            const temp = res.data.data;
            wx.config({
              beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
              debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
              appId: temp.appId, // 必填，企业微信的corpID
              timestamp: temp.timestamp, // 必填，生成签名的时间戳
              nonceStr: temp.nonceStr, // 必填，生成签名的随机串
              signature: temp.signature, // 必填，签名，见 附录-JS-SDK使用权限签名算法
              jsApiList: ['getContext'], // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
            });

            wx.ready(function () {
              callback && callback()
            });
          } else {

          }
        });


    },
    wxAgentConfig() {
      console.log(2)
      const that = this;
      let agentId = LocalStorage.getItem('agentId');
      let corpId = LocalStorage.getItem('corpId');
      this.axios.get('/' + this.$ajaxPrefix.consumer + '/v1/wx/cp/getAgentJsSdkConfig', {
        params: {
          agentId,
          corpId,
          url: encodeURIComponent(window.location.href.split('#'))
        }
      }).then(res => {
        console.log(3)
        if (res && res.data && res.data.data) {
          const temp = res.data.data;
          console.log(4)
          wx.agentConfig({
            corpid: corpId, // 必填，企业微信的corpid，必须与当前登录的企业一致
            agentid: agentId, // 必填，企业微信的应用id （e.g. 1000247）
            timestamp: temp.timestamp, // 必填，生成签名的时间戳
            nonceStr: temp.nonceStr, // 必填，生成签名的随机串
            signature: temp.signature,// 必填，签名，见附录-JS-SDK使用权限签名算法
            jsApiList: ['getCurExternalContact'], //必填
            success: function (res) {

              console.log(5)
              that.getid()
              console.log(7)

            },
            fail: function (res) {

            }
          });
        } else {

        }
      });
    },

    getid() {
      console.log('getid')
      var that = this;
      wx.invoke('getCurExternalContact', {
      }, function (res) {
        if (res.err_msg == "getCurExternalContact:ok") {
          console.log('getCurExternalContact', res)
          that.$toast.clear()
          that.$router.replace("/extcustomer/unregister?euid=" + res.userId);
    
   
        } else {
     
          //错误处理
        }
      });
    }
  },
};
